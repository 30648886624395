.logo
  display: flex
  align-items: center
  text-align: left
  justify-content: center
  @include for-size(max, phone-only)
    position: relative
    z-index: 55

  .logo-name
    font-family: Oswald, sans-serif
    text-transform: uppercase
    font-weight: 500
    font-size: larger
    max-width: min-content
    line-height: 1
    font-size: 20px
    img
      max-width: 220px

  .logo-desc
    font-size: 13px
    max-width: 100px
    line-height: 0.9
    opacity: 0.7
    margin-left: 10px
