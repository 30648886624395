.modal
  color: #000
  text-align: left

  .modal-title
    line-height: 1

  .svg-close
    cursor: pointer

  svg
    width: 30px
    height: 30px

  button
    @include for-size(max, phone-only)
      width: 100%
