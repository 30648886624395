.alert
  position: fixed
  top: 20px
  right: 0
  left: 0
  margin: auto
  width: max-content
  max-width: 100%
  padding: 15px 20px
  text-align: center
  color: #fff
  transition: all 0.3s
  border-radius: 3px
  box-shadow: 0 20px 40px -15px #000000ad
  z-index: 9999

  &.alert--success
    background-color: #4caf50

  &.alert--error
    background-color: #f44336
