// BRANDS
$brandColor1: #ac2a21
$brandColor2: #f44336
$brandColor3: #0075ff

// BTN
$btnColor: $brandColor1
$btnHoverColor: #de4e44
$btnDisabledColor: #7a7272

// Theme Mode
// Black 
$textWhite: #fff
$textWhiteGrey: #cecece
$borderBlack: #252525
$backBlack: #161616
// Light
$textBlack: #000
$borderGrey: #e3e3e3
$backWhite: #fff
$backGrey: #f8f8f8
