@keyframes thimbs-down
  0%
    bottom: 0px
  50%
    bottom: -5px
  100%
    bottom: 0px

.offer
  font-size: 18px

form
  background-color: #fff
  padding: 20px
  width: 100%
  border-radius: 5px
  box-shadow: 0 30px 70px -70px #000

  .field
    &-flex
      display: flex
      flex-direction: column
    &.form-check
      text-align: left
    &:not(:first-child)
      margin-top: 15px

  .feedback
    display: none
    line-height: 1.2
    margin-top: 10px
    font-size: 13px

  input
    &.policy
      cursor: pointer
    &.phone
      &.is-valid
        background-image: unset
  label
    color: #000
    &:not(.policy)
      text-transform: uppercase
      font-size: 12px
      opacity: 0.5
    &.policy
      font-size: 12px
      color: gray
      margin: 0

  .iti
    color: #000
    &:not(:first-child)
      margin-top: 5px
