.lozad
  &.show
    animation-name: show
    animation-duration: 2s
  &:not(.loaded)
    opacity: 0

@keyframes show
  from
    opacity: 0
  to
    opacity: 1
