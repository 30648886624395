.review
	margin: 10px
	.avatar
		border-radius: 50px
		overflow: hidden
		width: 60px
		height: 60px
		background-color: #eee
		display: flex
		align-items: center
		justify-content: center
		border: 4px solid #fff
		box-shadow: 0 7px 29px 0 #64646f33
	.text
		background-color: #fff
		padding: 20px
		border-radius: 5px
		box-shadow: 0 7px 29px 0 #64646f33
	.from
		font-size: 13px
		opacity: 0.7
		text-align: right
	.stars
		i
			font-size: 13px
			color: #ffb81c
			margin: 1px

	.info
		display: flex
		align-items: center
		gap: 15px
