.service
  .item
    border-radius: 0.25rem
    overflow: hidden
    box-shadow: #64646f33 0px 7px 29px 0px
    height: 100%

    mark
      background-color: var(--color-brand)
      width: 20px
      height: 20px
      position: absolute
      top: 10px
      left: 10px
      border-radius: 100%
      &:after
        content: ''
        position: absolute
        top: 0
        left: 0
        background: var(--color-brand)
        opacity: 0
        border-radius: 50%
        width: 100%
        height: 100%
        animation: sonar 2s 2s infinite

    .title
      &.active
        color: var(--color-brand)

    a
      font-size: 13px
      font-weight: 500
      text-transform: uppercase
      &:not(.active)
        &:nth-child(odd)
          background-color: #000
          color: #fff
        &:nth-child(even)
          background-color: #fff
          color: #000
      &:hover
        text-decoration: underline

      &.active
        background-color: var(--color-brand)
        color: #fff

@keyframes sonar
  0%
    transform: scale(0.9)
    opacity: 1
  100%
    transform: scale(2)
    opacity: 0
