// Media запросы
//@import "../../mixins/media"
//@include for-size(max, tablet-landscape-up)

@mixin for-size($mode, $size)
  @if $size == phone-only
    @media (#{$mode}-width: 599px)
      @content
  @else if $size == tablet-portrait-up
    @media (#{$mode}-width: 600px)
      @content
  @else if $size == tablet-landscape-up
    @media (#{$mode}-width: 900px)
      @content
  @else if $size == desktop-up
    @media (#{$mode}-width: 1200px)
      @content
  @else if $size == big-desktop-up
    @media (#{$mode}-width: 1800px)
      @content
  @else
    @media (#{$mode}-width: $size)
      @content
