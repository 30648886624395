.policy
  color: #000
  max-width: 800px
  h5
    margin-top: 40px
  .desc
    margin: 15px 0
  ol
    padding: 0
    list-style: none
  li
    margin: 10px 0 0 20px
