.whatsappdesc
	background-color: var(--color-brand)
	padding: 15px
	border-radius: 5px
	position: relative
	&:before
		border: 15px solid transparent
		border-bottom: 25px solid var(--color-brand)
		position: absolute
		content: ""
		top: -30px
		left: 0
		right: 0
		max-width: min-content
		margin: auto
